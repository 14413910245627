import {Component, OnDestroy, SecurityContext} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';
import {lastValueFrom} from 'rxjs';
import {marked} from 'marked';
import {SignInOutput} from 'aws-amplify/auth';

import {CardComponent} from '../../components/card/card.component';
import {CustomInputComponent} from '../../components/custom-input/custom-input.component';
import {
  LABEL_CONSTANT,
  LOGIN_CONSTANT,
  PAGE_TITLE_CONSTANT,
  PLACEHOLDER_CONSTANT,
  ROUTE_CONSTANT,
} from '../../constant/constant';
import {
  DomainOrganizationService,
  IDefaultOrgDetails,
} from '../../core/services/domain-organization.service';
import {ButtonComponent} from '../../components/button/button.component';
import {SystemAccessNotificationService} from '../../core/services/system-access-notification.service';
import {CognitoService} from '../../core/auth/cognito.service';
import {HttpErrorService} from '../../core/services/http-error.service';
import {CognitoOrgIdService} from '../../core/auth/cognito-org-id.service';
import {ValidationService} from '../../shared/services/validation.service';
import {AppAuthorizationService} from '../../core/auth/app-authorization.service';

export interface IOrganizationLoginForm {
  userName: FormControl<string | null | undefined>;
  password: FormControl<string | null>;
  newPassword: FormControl<string | null>;
  reEnteredpassword: FormControl<string | null>;
}
@Component({
  selector: 'app-organization-login',
  standalone: true,
  templateUrl: './organization-login.component.html',
  styleUrl: './organization-login.component.scss',
  imports: [ReactiveFormsModule, CardComponent, CustomInputComponent, ButtonComponent],
})
export class OrganizationLoginComponent implements OnDestroy {
  LABEL_CONSTANT = LABEL_CONSTANT;
  PLACEHOLDER_CONSTANT = PLACEHOLDER_CONSTANT;
  organizationLoginForm: FormGroup<IOrganizationLoginForm>;
  markdown?: string;
  isNewPasswordFlow: boolean = false;
  private readonly orgId: string;
  private clientId: string = '';

  constructor(
    private router: Router,
    private activatedRoutes: ActivatedRoute,
    private _domainOrganizationService: DomainOrganizationService,
    private _systemAccessNotificationService: SystemAccessNotificationService,
    private sanitizer: DomSanitizer,
    private _cognitoService: CognitoService,
    private _cognitoOrgIdService: CognitoOrgIdService,
    private _httpErrorService: HttpErrorService,
    private title: Title,
    private _appAuthorizationService: AppAuthorizationService,
  ) {
    this.organizationLoginForm = new FormGroup<IOrganizationLoginForm>({
      userName: new FormControl(
        {
          value: null,
          disabled: true,
        },
        [Validators.required, ValidationService.emailValidator()],
      ),
      password: new FormControl(null, Validators.required),
      newPassword: new FormControl(null),
      reEnteredpassword: new FormControl(null),
    });
    this.orgId = this.activatedRoutes.snapshot.params['orgId'];
    this._cognitoOrgIdService.orgId.set(this.orgId);
  }

  ngOnInit(): void {
    this.fetchUserNameFromURL();

    this._systemAccessNotificationService
      .getSystemAccessNotification()
      .subscribe((notes: string) => {
        this.markdown = this.sanitizer.sanitize(SecurityContext.HTML, marked(notes)) as string;
      });
  }

  private fetchUserNameFromURL(): void {
    this.activatedRoutes.queryParams.subscribe((params) => {
      const userName = params?.['login_hint'];
      this.clientId = params?.['client_id'];
      if (userName) {
        this.organizationLoginForm.setValue({
          userName: userName,
          password: null,
          newPassword: null,
          reEnteredpassword: null,
        });
      } else {
        this.organizationLoginForm.controls.userName.enable();
      }
      const orgDetails = this._cognitoService.getOrgDetails();
      if (orgDetails.defaultAppClientId && orgDetails.userPoolId) {
        this.clientId = this.clientId || orgDetails.defaultAppClientId;
        this.routeToApplicationIfAuthenticated(orgDetails);
      } else {
        this.getDefaultOrgDetails();
      }
    });
  }

  private async getDefaultOrgDetails(): Promise<void> {
    try {
      const result = await lastValueFrom(
        this._domainOrganizationService.getDefaultOrgDetails(this.orgId),
      );
      this.title.setTitle(
        PAGE_TITLE_CONSTANT.LOGIN + `${result.orgName ? ' | ' + result.orgName : ''} `,
      );
      this.clientId = this.clientId || result?.defaultAppClientId;
      if (!result?.userPoolId || !result?.defaultAppClientId) {
        this.router.navigate([ROUTE_CONSTANT.ORGANIZATION_NOT_FOUND]);
      } else {
        // default appclient Id used by the cognito user pool to store the auth information
        // if we use respective client ID then we face issue while logging into other application
        this._cognitoService.setOrgDetails(result);
        this.routeToApplicationIfAuthenticated(result);
      }
    } catch (error: any) {
      if (error.status === 404) {
        this.router.navigate([ROUTE_CONSTANT.ORGANIZATION_NOT_FOUND]);
      }
    }
  }

  public onSubmit(): void {
    this._httpErrorService.httpError.set('');
    if (!this.isNewPasswordFlow) {
      this.signIn();
    } else {
      this.onNewPassword();
    }
  }

  public signIn(): void {
    const {['userName']: email, password} = this.organizationLoginForm.getRawValue();
    this._cognitoService
      .signIn(
        {
          email: email as string,
          password: password as string,
        },
        this.orgId,
      )
      .then((data: SignInOutput) => {
        if (
          data?.nextStep?.signInStep === LOGIN_CONSTANT.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED
        ) {
          this.isNewPasswordFlow = true;
        } else {
          this._appAuthorizationService.routeToClientApp(this.orgId, this.clientId);
        }
      })
      .catch((error: Error) => {
        this._httpErrorService.httpError.set(error.message);
      });
  }

  public onNewPassword(): void {
    this._cognitoService
      .handleUpdatePassword(this.organizationLoginForm.get('reEnteredpassword')?.value as string)
      .then(() => {
        this._appAuthorizationService.routeToClientApp(this.orgId, this.clientId);
      });
  }

  private routeToApplicationIfAuthenticated(orgDetails: IDefaultOrgDetails): void {
    this._cognitoService.configureAuth(orgDetails);
    this._cognitoService.isAuthenticated().then((auth: boolean) => {
      if (auth) {
        this._appAuthorizationService.routeToClientApp(this.orgId, this.clientId);
      }
    });
  }

  ngOnDestroy(): void {
    this._httpErrorService.httpError.set('');
  }
}
