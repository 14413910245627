import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {Subscription} from 'rxjs';

import {CustomInputComponent} from '../../components/custom-input/custom-input.component';
import {CardComponent} from '../../components/card/card.component';
import {
  LABEL_CONSTANT,
  HTTP_ERROR_CONSTANT,
  ROUTE_CONSTANT,
  PLACEHOLDER_CONSTANT,
} from '../../constant/constant';
import {DomainOrganizationService, IOrganization} from '../../core/services/domain-organization.service';
import {ButtonComponent} from '../../components/button/button.component';
import {FormControlPipe} from '../../shared/pipes/formControl.pipe';
import {HttpErrorService} from '../../core/services/http-error.service';
import {ValidationService} from '../../shared/services/validation.service';

export interface ISignInForm {
  userName: FormControl<string | undefined | null>;
}
@Component({
  selector: 'app-sign-in',
  standalone: true,
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.scss',
  imports: [
    ReactiveFormsModule,
    CustomInputComponent,
    CardComponent,
    FormControlPipe,
    ButtonComponent,
  ],
})
export class SignInComponent implements OnInit, OnDestroy {
  LABEL_CONSTANT = LABEL_CONSTANT;
  ERROR_CONSTANT = HTTP_ERROR_CONSTANT;
  PLACEHOLDER_CONSTANT = PLACEHOLDER_CONSTANT;

  handleSubscription: Subscription[] = [];

  signInForm: FormGroup<ISignInForm>;

  constructor(
    private router: Router,
    private _domainOrganizationService: DomainOrganizationService,
    private _httpErrorService: HttpErrorService,
  ) {
    this.signInForm = new FormGroup<ISignInForm>(
      {
        userName: new FormControl(undefined, [
          ValidationService.emailValidator(),
          Validators.required,
        ]),
      },
      {
        updateOn: 'submit',
      },
    );
  }

  ngOnInit() {
    this.handleSubscription?.push(
      this._domainOrganizationService.organizations.subscribe((data) => {
        if (data) {
          this.onOrganizationFetch(data);
        }
      }),
    );
    if (this.signInForm.get('userName')) {
      this.handleSubscription?.push(
        (
          this.signInForm.get('userName') as AbstractControl<
            Partial<{userName: string | null | undefined}>
          >
        ).valueChanges.subscribe((value) => {
          this._httpErrorService.httpError.set('');
        }),
      );
    }
  }

  public onSubmit(): void {
    if (this.signInForm.value.userName && this.signInForm.valid) {
      this._domainOrganizationService.userName = this.signInForm.value.userName;
      this._domainOrganizationService
        .getOrganizationsUsingLoginName(this._domainOrganizationService.userName)
        .subscribe((organizations) => {
          this._domainOrganizationService.organizations.next(organizations);
        });
    } else if (this.signInForm.pristine) {
      this.signInForm.get('userName')?.updateValueAndValidity();
    }
  }

  private onOrganizationFetch(organizations: IOrganization[]): void {
    if (organizations.length > 1) {
      this.router.navigate([ROUTE_CONSTANT.SELECT_ORGANIZATION], {
        queryParams: {login_hint: this._domainOrganizationService.userName},
      });
    } else if (organizations.length === 1) {
      this.router.navigate(
        [ROUTE_CONSTANT.LOGIN_ORGANIZATION_NAME.replace(':orgId', organizations[0].orgId)],
        {
          queryParams: {login_hint: this._domainOrganizationService.userName},
        },
      );
    } else if (organizations.length === 0) {
      this._httpErrorService.httpError.set(this.ERROR_CONSTANT.ORGANIZATION_NOT_FOUND);
    }
  }

  ngOnDestroy() {
    this.handleSubscription?.forEach((subscription) => subscription?.unsubscribe());
    this._httpErrorService.httpError.set('');
  }
}
