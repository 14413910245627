import {HttpClient, HttpContext} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {FILE_CONSTANTS} from '../../constant/apiConstants';

@Injectable({
  providedIn: 'root',
})
export class SystemAccessNotificationService {
  constructor(private http: HttpClient) {}

  getSystemAccessNotification(): Observable<string> {
    return this.http.get(FILE_CONSTANTS.PRIVACY_NOTE, {
      responseType: 'text',
    });
  }
}
