import {Routes} from '@angular/router';
import {SignInComponent} from './pages/sign-in/sign-in.component';
import {OrganizationLoginComponent} from './pages/organization-login/organization-login.component';
import {HTTP_ERROR_CONSTANT, PAGE_TITLE_CONSTANT, ROUTE_CONSTANT} from './constant/constant';

export const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {
    path: ROUTE_CONSTANT.LOGIN_ORGANIZATION_NAME,
    component: OrganizationLoginComponent,
    title: PAGE_TITLE_CONSTANT.LOGIN,
    data: {footer: true},
  },
  {
    path: ROUTE_CONSTANT.SELECT_ORGANIZATION,
    loadComponent: () =>
      import('./pages/select-organization/select-organization.component').then(
        (m) => m.SelectOrganizationComponent,
      ),
    title: PAGE_TITLE_CONSTANT.SELECT_ORGANIZATION,
    data: {footer: true},
  },
  {
    path: ROUTE_CONSTANT.LOGIN,
    component: SignInComponent,
    title: PAGE_TITLE_CONSTANT.LOGIN,
    data: {footer: true},
  },
  {
    path: ROUTE_CONSTANT.ORGANIZATION_NOT_FOUND,
    loadComponent: () =>
      import('./pages/not-found/not-found.component').then((m) => m.NotFoundComponent),
    title: PAGE_TITLE_CONSTANT.ORGANIZATION_NOT_FOUND,
    data: {errorMessage: HTTP_ERROR_CONSTANT.ORGANIZATION_ERROR_PAGE},
  },
  {path: '**', redirectTo: '/login'},
];
