<app-card>
  <form class="form-container" [formGroup]="signInForm" (ngSubmit)="onSubmit()">
    <div>
      <app-custom-input
        [formControl]="signInForm.controls.userName"
        type="text"
        id="username"
        label="{{ LABEL_CONSTANT.USERNAME_EMAIL }}"
        aria-label="User name"
        aria-describedby="username-helper"
        icon="user-input-icon.svg"
        placeholder="{{ PLACEHOLDER_CONSTANT.EMAIL_FORMAT }}"
      />
    </div>
    <app-button
      type="submit"
      className="primary-form-button"
      aria-label="Next"
      [label]="LABEL_CONSTANT.NEXT"
    />
  </form>
</app-card>
