<app-card [systemAccessNotification]="markdown">
  <form class="form-container" [formGroup]="organizationLoginForm" (ngSubmit)="onSubmit()">
    <div>
      @if (!isNewPasswordFlow) {
        <app-custom-input
          formControlName="userName"
          type="text"
          id="username"
          label="{{ LABEL_CONSTANT.USERNAME_EMAIL }}"
          aria-label="User name"
          aria-describedby="username-helper"
          icon="user-input-icon.svg"
          placeholder="{{ PLACEHOLDER_CONSTANT.EMAIL_FORMAT }}"
        />
        <app-custom-input
          formControlName="password"
          type="password"
          id="password"
          label="{{ LABEL_CONSTANT.PASSWORD }}"
          aria-label="password"
          aria-describedby="user password"
          icon="lock.svg"
          [showHide]="true"
        />
      } @else {
        <app-custom-input
          formControlName="newPassword"
          type="password"
          id="new-password"
          label="{{ LABEL_CONSTANT.NEW_PASSWORD }}"
          aria-label="new password"
          icon="lock.svg"
          [showHide]="true"
        />
        <app-custom-input
          formControlName="reEnteredpassword"
          type="password"
          id="re-entered-password"
          label="{{ LABEL_CONSTANT.RE_ENTER_PASSWORD }}"
          aria-label="password"
          aria-describedby="enter password again"
          icon="lock.svg"
          [showHide]="true"
        />
      }
    </div>
    <app-button
      type="submit"
      [disable]="!organizationLoginForm.valid"
      className="primary-form-button"
      aria-label="Login"
      [label]="LABEL_CONSTANT.LOGIN"
    />
  </form>
</app-card>
